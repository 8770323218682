.logo-icon{
    animation-name: animation-logo-icon;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    transform: translateX(-112px);
    opacity: 1;
}
@keyframes animation-logo-icon {
    0% {
        opacity: 0;
        transform: translateX(-60px);
    }
    35% {
        opacity: 0;
        transform: translateX(-60px);
    }
    100% {
        opacity: 1;
        transform: translateX(-112px);
    }
}

.logo-text{
    animation-name: animation-logo-text;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    transform: translateX(18px);
    opacity: 1;
}
@keyframes animation-logo-text {
    0% {
        opacity: 0;
        transform: translateX(0px);
    }
    35% {
        opacity: 0;
        transform: translateX(0px);
    }
    100% {
        opacity: 1;
        transform: translateX(18px);
    }
}

.splash-loading {
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 20px;
    background: #66CC00;
    animation-name: animation-logo-loading;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    opacity: 1;
}
@keyframes animation-logo-loading {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



.logo-powered{
    animation-name: animation-logo-powered;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    opacity: 1;
}
@keyframes animation-logo-powered {
    0% {
        opacity: 0;
    }
    35% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* 
Animated.timing(this.state.powered, {toValue: 1, duration: 500, delay: 1500, useNativeDriver: false}),
Animated.timing(this.state.loading, {toValue: 1, duration: 500, delay: 1500, useNativeDriver: false}) 
*/