.activity-indicator {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center
  }
.activity-indicator div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60%;
    height: 60%;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: activity-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.activity-indicator div:nth-child(1) {
    animation-delay: -0.45s;
}
.activity-indicator div:nth-child(2) {
    animation-delay: -0.3s;
}
.activity-indicator div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes activity-indicator {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}