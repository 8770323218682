@import url('./assets/fonts/fonts.css');

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
  background: transparent;
  border: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

#root {
  min-height: 100%;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}

.app {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 10;
  transition: all .3s ease;
}

.app.isOpen {
  transform: translateX(-300px);
}

.app.isOpen > .navbg {
  display: none;
  background: transparent;
  transition: all .3s ease;
}

.app.isOpen > .navbg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0,0,0,.5);
}

.SnackbarContainer-rootDense.SnackbarContainer-top {
  top: 50px !important;
}

button {
  background-color: transparent;
  border: none;
}

a{
  text-decoration: none;
}

p{
  margin: 0;
  margin-bottom: 20px;
}

.animate {
  transition: all ease .3s;
}

.pulse {
  animation-name: pulse;
  animation-duration: .5s;
  animation-fill-mode: both;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse2D {
  from {
    transform: scale(1, 1);
  }

  10% {
    transform: scale(1.1, 1.1);
  }

  to {
    transform: scale(1, 1);
  }
}