.qrScanOverride,
.qrScanOverride > section {
    height: 100%;
    padding: 0 !important;
}


.qrScanOverride > section div {
    --paddingT: calc(calc(var(--vh, 1vh)*40) - 120px);
    --paddingB: calc(calc(var(--vh, 1vh)*60) - 120px);
    --paddingLR: calc(calc(var(--vw, 1vw)*50) - 120px);
    border-top: var(--paddingT) solid rgba(0, 0, 0, 0.3) !important;
    border-bottom: var(--paddingB) solid rgba(0, 0, 0, 0.3) !important;
    border-left: var(--paddingLR) solid rgba(0, 0, 0, 0.3) !important;
    border-right: var(--paddingLR) solid rgba(0, 0, 0, 0.3) !important;
    box-shadow: none !important;
    background-image: url(../../assets/images/scan-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 240px 240px;
}

.qrHeader {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    bottom: 15%;
    z-index: 998
}

.qrHeading {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    color: #ffffff;
}

.qrSubheading {
    font-size: 17px;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}